import React from 'react'
import { graphql } from 'gatsby'
import ExtendedMdxRender from '../components/extendedMdxRender'
import SEO from '../components/seo'
import theme from '../../config/theme'
import Container from '../components/container'
import Layout from '../components/layout'
import { css } from '@emotion/core'
import get from 'lodash/get'
import { bpMaxSM } from '~lib/breakpoints'

export default function StudioPage(props) {
  return <Studio {...props} />
}

function Studio({ data: { site, mdx, noFooter = false } }) {
  const { title } = mdx.fields
  return (
    <Layout
      site={site}
      frontmatter={mdx.fields}
      headerLink="/studio"
      noFooter={mdx.fields.noFooter}
      headerColor={theme.colors.white}
    >
      <SEO
        frontmatter={mdx.fields}
        metaImage={get(mdx, 'fields.banner.childImageSharp.fluid.src')}
        isBlogPost
      />
      <article
        css={css`
          width: 100%;
          display: flex;
          twitter-widget {
            margin-left: auto;
            margin-right: auto;
          }
        `}
      >
        <Container
          css={css`
            padding-top: 0;
          `}
        >
          {title ? (
            <div
              css={css`
                padding: 40px 0 0 0;
                ${bpMaxSM} {
                  padding: 20px 0 0 0;
                }
                h1 {
                  font-size: 1.75rem;
                  font-family: sans-serif;
                }
              `}
            >
              <h1>{title}</h1>
            </div>
          ) : null}

          <div
            css={css`
              display: flex;
              justify-content: center;
              h3,
              span {
                text-align: center;
                font-size: 15px;
                opacity: 0.6;
                font-family: sans-serif;
                font-weight: normal;
                margin: 0 5px;
              }
            `}
          />
          <ExtendedMdxRender>{mdx.body}</ExtendedMdxRender>
        </Container>
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        keywords
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      fields {
        title
        noFooter
        description
        date
        author
        banner {
          ...bannerImage2000
        }
        noGlobalStyles
        bannerCredit
        slug
        description
        keywords
      }
      body
    }
  }
`
